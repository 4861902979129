<!-- 渠道房量 -->
<template>
  <section class="cont" >
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <!-- <el-breadcrumb-item>{{ pageType ? 'eBooking' : 'PMS' }}</el-breadcrumb-item> -->
           <el-breadcrumb-item>PMS</el-breadcrumb-item>
           <el-breadcrumb-item>{{pageType !== 'num' ? '房价日历' : '渠道房量'}}</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>

    <el-row class="content-box"  >
      <el-row class="right-tit table-box">
        <span></span>
        {{pageType === "num" ? "房间数量" : "房价日历"}}
      </el-row>
      <!--   头部筛选项   -->
       <el-row class="right-title" type="flex" justify="space-between" v-if="this.pageUrl == '/bargaining/price_template' ">
          <el-col :lg="{span:10}">
             <span class="label">选择模板：</span>
             <el-select v-model="value" clearable placeholder="请选择" @change="getPrice">
                <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.templateName"
                      :value="item.id">
                </el-option>
             </el-select>
             <el-button type="primary" style="margin-left: 30px" @click="outerVisible = true">模板管理</el-button>
          </el-col>
       </el-row>

      <el-row class="right-title" type="flex" justify="space-between">
        <el-col :lg="{span:10}">
            <span class="label">选择日期：</span>
            <el-date-picker value-format="yyyy-MM-dd" :clearable="false" class="width-140" @change="getPrice" :editable="false" v-model="beginTime" type="date"/>
            <span class="date-btn front" @click="changeDate('-')"> 前七天 </span>
           <span class="date-btn queen" @click="changeDate('+')"> 后七天 </span>
        </el-col>
        <el-col v-if="!pageType && $route.name != 'bargainingPriceTemplate'" :lg="{span:6}" :xl="{span:4}" :md="{span:10}">
            <el-checkbox class="c-red" v-model="vipPrice">会员价</el-checkbox>
            <el-checkbox class="c-gray" v-model="plainPrice">散客价</el-checkbox>
        </el-col>
        <el-col v-if="pageType == 'num' && this.pageUrl == '/Channel_inventory'" :lg="{span:6}" :xl="{span:4}" :md="{span:10}">
          <el-checkbox class="c-red" v-model="totalAmount" @change="changeShow('totalAmount',totalAmount)">总房量</el-checkbox>
          <el-checkbox class="c-gray" v-model="residueAmount" @change="changeShow('residueAmount',residueAmount)" >剩余房量</el-checkbox>
        </el-col>
        <el-col v-if="pageType || this.pageUrl == '/bargaining/price_template' " style="text-align:right" :lg="{span:6}" :xl="{span:4}" :md="{span:10}">

          <el-button @click="showEdit = true" type="primary">批量修改</el-button>
        </el-col>
      </el-row>
      <!--  一周房价信息  -->
      <el-tabs v-loading="loading" id="priceTab" v-model="pmsSourceType" type="border-card" @tab-click="changeChannel" v-if="pageUrl != '/bargaining/price_template'">
        <el-tab-pane :label="item.label" :name="item.value" v-for="(item,index) in platform" :key="index">
        <!-- <el-tab-pane :label="item.label" :name="item.value" v-for="(item,value) in platform" :key="value"> -->
          <el-row class="tab-header table-box">
            <el-col :span="3" class="min-w">
              {{!pageType ? '房型/会员/散客' : "房型"}}
            </el-col>
            <!-- <el-col :span="3" class="min-w">基础价</el-col> -->
            <el-col :span="3" v-for="(item, day) in priceData[priceDataFristKey]" :key="day" class="min-w">
              <p>{{item.priceDate | dateFilter}}</p>
              <p>{{ item.priceDate | weekFilter}}</p>
            </el-col>
          </el-row>
          <el-row class="tab-body-item table-box" v-for="( item, index ) in priceData" :key="index">
            <el-col :span="3" class="min-w">
              <span>{{index}}</span>
            </el-col>
            <el-col v-for="( items, indexs) in priceData[index]" :key="indexs" :span="3" class="min-w">
              <el-row >
                <span v-if="!pageType && $route.fullPath != '/bargaining/price_template' ">
                  <input type="number" min="0" v-show="vipPrice" @focus="saveBeforeData(items)" @change="isUpdate = true" @blur="changePrice(priceData,index,indexs)" class="price-ipt c-red" v-model="items.memberPrice"/>
                  <input type="number" min="0" v-show="plainPrice" @focus="saveBeforeData(items)" @change="isUpdate = true" @blur="changePrice(priceData,index,indexs)" class="price-ipt" v-model="items.roomPrice"/>
                </span>
              </el-row>

              <el-row >
                <span v-if="pageType && $route.fullPath != '/bargaining/price_template' ">
                 <input type="number" min="0" v-if="pageType === 'price'" @focus="saveBeforeData(items)" @change="isUpdate = true" @blur="changePrice(priceData,index,indexs)" class="price-ipt" v-model="items.ebookingPrice"/>
                <input type="number" min="0" v-if="pageType === 'num'" v-show="totalAmount" @focus="saveBeforeData(items)" @change="isUpdate = true" @blur="changePrice(priceData,index,indexs)" class="price-ipt" v-model="items.ebookingNum"/>
                </span>
                <span v-if="pageType == 'num' && pageUrl == '/Channel_inventory'">
                  <label v-show="residueAmount">/剩余{{items.checkInCount || 0}}</label>
                </span>
              </el-row>
              <el-row v-if="pageUrl == '/bargaining/price_template'">
                <input type="number" min="0"  @focus="saveBeforeData(items)" @change="isUpdate = true" @blur="changePrice(priceData,index,indexs)" class="price-ipt" v-model="items.contractPrice"/>
              </el-row>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
<!-- 协议单位价格-->
      <el-tabs v-loading="loading"  v-model="pmsSourceType" type="border-card" @tab-click="changeChannel" v-else>
        <el-row class="tab-header table-box">
          <el-col :span="3" class="min-w">
            {{!pageType ? '房型/会员/散客' : "房型"}}
          </el-col>
          <!-- <el-col :span="3" class="min-w">基础价</el-col> -->
          <el-col :span="3" v-for="(item, day) in priceData[priceDataFristKey]" :key="day" class="min-w">
            <p>{{item.priceDate | dateFilter}}</p>
            <p>{{ item.priceDate | weekFilter}}</p>
          </el-col>
        </el-row>
        <el-row class="tab-body-item table-box" v-for="( item, index ) in priceData" :key="index">
          <el-col :span="3" class="min-w">
            <span>{{index}}</span>
          </el-col>
          <el-col v-for="( items, indexs) in priceData[index]" :key="indexs" :span="3" class="min-w">
            <el-row >
              <input type="number" min="0"  @focus="saveBeforeData(items)" @change="isUpdate = true" @blur="changePrice(priceData,index,indexs)" class="price-ipt" v-model="items.contractPrice"/>
            </el-row>
          </el-col>
        </el-row>
      </el-tabs>
     <el-checkbox v-model="quantityType" @change="saveRoomQuantityConfig()" style="margin: 10px 0 0 20px">直连房态中心</el-checkbox>
    </el-row>
<!--价格模板-->
     <el-dialog title="价格模板" :visible.sync="outerVisible">
        <el-button type="primary" style="float: right" @click="emidt('','新增模板')">新增模板</el-button>
        <el-table :data="options" height="400">
           <el-table-column label="序号" type="index" />

           <el-table-column label="模板名称" prop="templateName" />

           <el-table-column label="操作">
              <template slot-scope="scope">
                 <el-button type="text" @click="emidt(scope.row,'编辑模板')">编辑</el-button>
                 <el-button type="text" @click="deleterow(scope.row)">删除</el-button>
              </template>
           </el-table-column>
        </el-table>
        <el-dialog
              width="30%"
              :title="dagtitle"
              :visible.sync="innerVisible"
              append-to-body>
           <el-form  :model="ruleForm" :rules="rules" label-width="100px" ref="ruleForm">
              <el-form-item label="模板名称" prop="templateName">
                 <el-input v-model="ruleForm.templateName"></el-input>
              </el-form-item>
           </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="savetemplate">确 定</el-button>
          </div>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
           <el-button @click="outerVisible = false">取 消</el-button>
           <el-button type="primary" @click="outerVisible = false">确 定</el-button>
        </div>
     </el-dialog>

<!--批量修改-->
    <el-dialog title="批量修改" :visible.sync="showEdit" width="650px">
      <el-form>
        <el-form-item label="选择时间" required>
          <el-date-picker
            @change="selectDateRange"
            value-format="yyyy-MM-dd"
            v-model="dateTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择星期" required>
          <el-checkbox-group v-model="checkWeek" style="text-align: left;display: inline-block" >
            <el-checkbox v-for="(item,index) in weekList" :key="index" :label="item">
              {{ index | weekFilter }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <el-table :data="checkedCities" height="400">
        <el-table-column label="房型">
          <template slot-scope="scope">
            {{checkedCities[scope.$index]}}
          </template>
        </el-table-column>
        <el-table-column :label="pageType === 'num' ? '房量':'基础房价'">
          <template slot-scope="scope">
            <span v-if="pageType !== 'num'">{{priceData[checkedCities[scope.$index]][0].ebookingPrice}}</span>
            <span v-else>{{priceData[checkedCities[scope.$index]][0].ebookingNum}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="pageType === 'num' ? '修改房量':'修改房价'">
          <template slot-scope="scope">
            <el-input type="number" min="0" v-model="priceData[checkedCities[scope.$index]][0].roomPriceUpdate"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="showEdit = false">取消</el-button>
        <el-button type="primary" @click="savePrice">确定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
import { dateFactory, getDict } from '@/common/js/common'
export default {
  data(){
    return{
      // EBOOKING的特有参数
      dateTime:[], // 批量修改中的日期选择
      pageType:"", // 页面类型 // price:Ebooking房价日历;num:房量
      checkedCities:[], // 已选中的房型
      showEdit:false, // 显示批量修改框
      outerVisible:false,
      innerVisible:false,
      checkWeek: [],
      weekList: ["sun","mon", "tue", "wed", "thu", "fri", "sat"],
      platform:"",
      platforms:"",
      platformQ:'',
      hotelId:"", // 酒店ID
      priceData:[], // 价格数据
      priceDataFristKey:"",
      pageUrl:'',
      dagtitle:'', // 模板弹窗标题
      beginTime:"", // 查询时间
      channelType:"", // 渠道类型
      url:'',
      pragma:'',
      channelList:[],
      options:[],
      value:'',
      loading:false,
      vipPrice:true,
      totalAmount:true,
      residueAmount:true,
      plainPrice:true,
      ruleForm:{
        templateName:'',
        Id:'',
        hotelId:''
      },
      pmsSourceType: "", // 来源与当前选中的Tab
      isUpdate:false, // 标记是否修改数据
      beforePrice:{}, // 如果取消修改就用这里的数据替换掉原有数据
      rules:{
        templateName:[{ required: true, message: '请输入模板名称', trigger: 'blur'}],
      },
      quantityType: false
    }
  },
  mounted() {
    this.pageType = this.$route.query.pageType
    this.vipPrice = !this.pageType

    this.beginTime = dateFactory.getDistanceToday(0,false)
    this.dateTime = [this.beginTime, dateFactory.getDistanceToday(1,false)]
    getDict(["order-source"],false)
    this.getRoomQuantityConfig()
  },

  methods: {
     //获取当前路由URL
     getPath(){
       this.hotelId = this.hotelInfo.id
       this.ruleForm.hotelId = this.hotelInfo.id
        this.pageUrl = this.$route.path
       if (this.pageUrl == '/bargaining/price_template'){
         this.getpriceTemplate()
       }else{
         this.getPrice()
       }
       // if (this.pageType == 'num' && this.pageUrl == '/Channel_inventory'){
       //   if (localStorage.getItem('totalAmount') != null) this.totalAmount = localStorage.getItem('totalAmount')
       //   if (localStorage.getItem('residueAmount') != null)this.residueAmount = localStorage.getItem('residueAmount')
       // }
     },
    //控制房量显示
    changeShow(val,flag){
      localStorage.setItem(val,flag)
    },
    //获取价格模板
    getpriceTemplate(){
     let url = pms.getpriceTemplate,
         params = {
            hotelId:this.hotelInfo.id
         }
      this.$axios.post(url, params,'json').then( res => {
        if(res.status){
          this.options = res.records
          if (this.options.length != 0){
            this.value = this.options[0].id
            this.getPrice()
          }
        }
      })
    },
     //获取到要编辑的数据
     emidt(val,type){
      this.innerVisible = true
      this.dagtitle = type
       if (val != ''){
         this.ruleForm.templateName = val.templateName
         this.ruleForm.Id = val.id
         this.ruleForm.hotelId = this.hotelInfo.id
       }
     },
    //保存模板
    savetemplate(){
      const url =  pms.savepriceTemplate
      let params = this.ruleForm
      this.$axios.post(url,params).then( res => {
        if(res.status) {
          this.innerVisible = false
          this.getpriceTemplate()
          this.$message.success('保存成功')
          this.ruleForm.templateName = ''
          this.ruleForm.Id = ''
        }
      })
    },
     //删除
     deleterow(val){
       this.$confirm('注意：删除后如果有协议单位使用该模板，将会失效！', '提示', {
         confirmButtonText: this.confirm,
         cancelButtonText: this.cancel,
         type: 'warning'
       }).then(() => {
          const url = pms.deletepriceTemplate + val.id
          let params = {}
          this.$axios.post(url,params).then( res => {
            if(res.status) {
              this.innerVisible = false
              this.getpriceTemplate()
              this.$message.success('删除成功')
            }
          })
       })
     },
    savePrice(){ // 保存批量修改的价格
      let msg
      if(!this.checkWeek.length){ msg = msg || "请选择生效时间"  }
      if(!this.dateTime[0] || !this.dateTime[1]){ msg = msg || "请选择完整时间"; }
      if(msg){
        this.$message.warning(msg)
        return
      }

      this.pragma = {
        beginTime:this.dateTime[0], // 开始时间
        endTime:this.dateTime[1], // 结束时间
        hotelId:this.hotelInfo.id,
        pmsSourceType:this.pmsSourceType,
        priceCalendarDTOS:[],
        priceTemplateDataDTOS:[]
      };
      this.weekList.forEach( item => {
        if(this.checkWeek.indexOf(item) !== -1){
          this.pragma[item] = 1
        }else{
          this.pragma[item] = 0
        }
      })
      this.checkedCities.forEach( item => {
        if( this.priceData[item][0].roomPriceUpdate ){
          let obj = {
            roomTypeId:this.priceData[item][0].roomTypeId // id
          }
          if(this.pageType !== 'num' || this.$route.name == 'bargainingPriceTemplate'){
            obj.ebookingPrice = this.priceData[item][0].roomPriceUpdate // 基础价格
          }else if (this.pageType == 'num' && this.$route.name != 'bargainingPriceTemplate'){
            obj.ebookingNum = this.priceData[item][0].roomPriceUpdate
          }

          if (this.$route.name == 'bargainingPriceTemplate'){
            obj.contractPrice = this.priceData[item][0].roomPriceUpdate // 基础价格
            this.pragma.priceTemplateDataDTOS.push(obj)
            delete this.pragma.pmsSourceType

          }else{
            this.pragma.priceCalendarDTOS.push(obj)
            delete this.pragma.priceTemplateDataDTOS

          }
        }
      })
      if (this.pageUrl == '/bargaining/price_template'){
        this.url = pms.saveBatchPriceTemplateData
        this.pragma.templateId = this.value
      }else{
        this.url = "/pms/priceCalendar/saveBatchPMSCustomPrice"
      }
      this.$axios.post(this.url, this.pragma, 'json').then( res => {
        if(res.status){
          this.showEdit = false
          this.$message.success("修改成功")
          this.getPrice()
        }
      })
    },

    selectAllItem(){ // 全选EBOOKING房价信息
      let len = 0,
          arr = [];
      for(let key in this.priceData){
        len += 1
        arr.push(key)
      }
      if(this.checkedCities.length < len){
        this.checkedCities = [...new Set(arr)]
      }
    },

    saveBeforeData(items){
      this.beforePrice = JSON.parse(JSON.stringify(items))
    },

    changePrice(data,index,childIndex){ // 输入框失去焦点时触发
      if(!this.isUpdate){
        return
      }

      let msg = !this.pageType  || this.pageType === "price" ? "价格信息" : "房间数量"
      this.$confirm(`是否修改当前${msg}?`, '提示', {
        type: 'warning'
      }).then(() => {
        let params = data[index][childIndex];

        if (this.pageUrl == '/bargaining/price_template'){
          this.url = pms.savePriceTemplateData
          if (Number(data[index][childIndex].contractPrice) <0 || data[index][childIndex].contractPrice == '') {
            this.$message.warning("请输入正确的当日房价")
            this.priceData[index].splice(childIndex,1,this.beforePrice)
            delete params.pmsSourceType
            return;
          }
        }else{
          this.url = "/pms/priceCalendar/savePmsCustomPrice"
          if(this.pageType === "num" && (!params.ebookingNum || params.ebookingNum < 0)){
            this.$message.warning("请输入正确的房间数")
            this.priceData[index].splice(childIndex,1,this.beforePrice)
            return;
          }
          if(this.pageType === "price" && (!params.ebookingPrice || params.ebookingPrice < 0)){
            this.$message.warning("请输入正确的当日房价")
            this.priceData[index].splice(childIndex,1,this.beforePrice)
            return;
          }
          if(!this.pageType && (!params.roomPrice || !params.memberPrice)){
            this.$message.warning("请输入正确的当日会员/散客房价")
            this.priceData[index].splice(childIndex,1,this.beforePrice)
            return;
          }
          params.pmsSourceType = this.pmsSourceType;

        }
        delete params.isDelete
        delete params.isEbooking
        delete params.roomTypeSource
        params.hotelId = this.hotelInfo.id

        this.$axios.post(this.url,params).then( res => {
          this.isUpdate = false
          if(res.code === "SUCCESS"){
            this.getPrice()
            this.$message.success("修改成功")
          }else{
            this.$message.warning("处理失败")
            this.priceData[index].splice(childIndex,1,this.beforePrice)
          }
        })
      }).catch(() => {
        // items = this.beforePrice
        this.priceData[index].splice(childIndex,1,this.beforePrice)
        this.isUpdate = false
        this.$message.info("已取消修改");
      });
    },

    changeChannel(e){ // 点击tabs切换价格渠道
      this.pmsSourceType = e.name
      this.getRoomQuantityConfig()
      this.getPrice()
    },
    changeDate(method){ // 点击按钮触发时间改变
      this.beginTime = dateFactory.getDistanceAppoint(this.beginTime, method, 7, false);
      this.getPrice()
    },

    getPrice(){ // 获取价格信息
           if (this.pageUrl == '/bargaining/price_template'){

             this.url = pms.getPriceTemplateDataList
             this.pragma = {
               beginTime: this.beginTime,
               hotelId:this.hotelInfo.id,
               roomTypeSource:this.pmsSourceType,
               templateId:this.value
             }
             if(this.hotelInfo.isEbooking == 'YES'){
               this.pragma.roomTypeSource = 'EBOOKING'
             } else{
               this.pragma.roomTypeSource = 'PMS'
             }

           }else{
             this.url = "/pms/priceCalendar/getPmsPriceCalendarList"
             this.pragma = {
               beginTime: this.beginTime,
               hotelId:this.hotelInfo.id,
               pmsSourceType:this.pmsSourceType
             }
           }



      if(this.pageType){ // 页面类型 EBOOKING 表示 EBOOKING模块
        this.pragma.roomTypeSource = "EBOOKING"
      }
      this.loading = true
      this.$axios.post(this.url,this.pragma).then( res => {
        this.loading = false
        this.priceData = res.records
        this.selectAllItem()
        for(let key in res.records){ // 取返回对象第一个key值
          this.priceDataFristKey = key;
          return
        }
      }).catch( err => {
        this.loading = false
        this.$message.error("数据请求失败")
      })
    },

    selectDateRange(dateRange){ // 批量修改中的日期选择器限制时间跨度
      let num = dateFactory.getDistanceDays(dateRange[0], dateRange[1])
      if(num > 365){
        this.dateTime = []
        this.$message.warning("选择的时间跨度不允许超过一年，请重新选择")
      }
    },

    // 获取房量直连配置
    getRoomQuantityConfig() {
      let url = '/pms/config/getConfig/quantity_type'
      let params = {hotelId: this.hotelInfo.id, channel: this.pmsSourceType}
      this.$axios.post(url,params, 'json').then( res => {
           if (res.records) {
            this.quantityType = res.records.content == 'YES' ? true : false
           } else {
            this.quantityType = false
           }
          })
    },

    // 保存房量直连配置
    saveRoomQuantityConfig() {
      let url = '/pms/config/saveRoomQuantityConfig'
      let params = {hotelId: this.hotelInfo.id,channel: this.pmsSourceType, content: this.quantityType ? 'YES' : 'NO'}
      this.$axios.post(url,params, 'json').then( res => {
        this.getRoomQuantityConfig()
      })
    }

  },
  filters:{
    weekFilter(val){
      if(typeof val !== 'number'){
        val = new Date(val).getDay()
      }
      let week = ["周日","周一","周二","周三","周四","周五","周六"]
      return week[val]
    },
    dateFilter(val){
      let MM = new Date(val).getMonth() + 1,
          dd = new Date(val).getDate();
      MM < 10 ? MM = 0 + "" + MM : MM;
      dd < 10 ? dd = 0 + "" + dd : dd;
      return MM + "-" + dd;
    }
  },
  computed:{
    ...mapState(['dictData','hotelInfo'])
  },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               // this.getPrice();
              this.value = ''
              this.priceData = []
              this.getPath()

            }
         },
         deep: true
      },
      dictData:{
        handler(){
          this.platforms = this.dictData['order-source-list'].filter(item => {
            if(item.value != "FRONT_DESK" && item.value != "SSM" && item.value != "WEB" && item.value != "APP" && item.value != "GIFT"  ){
              return item
            }
          })
           this.platformQ = this.dictData['order-source-list'].filter(item => {
              if(item.value != "GIFT" ){
                 return item
              }
           })
          this.platform = this.pageType ? this.platforms : this.platformQ
          this.pmsSourceType = this.platform[0].value
          this.getPath()
        },
        deep:true
      },
      "$route.query.pageType":{
        handler(){
          this.platform = this.pageType ? this.platforms : this.platformQ
          this.pmsSourceType = this.platform[0].value
          this.pageType = this.$route.query.pageType;
          this.selectAll = false;
          // this.getPrice();
          this.getPath()
        }
      }
   }
}
</script>

<style scoped lang="scss">

$vip-color:#FF2332;
$plain-color:#909399;
.right-box{
  min-width: 980px;
  background: #fff;
  padding:20px 50px;
}
.right-tit{
  background:#EFEFEF;
  padding:5px 20px;
  //align-items:center;
  font-weight:bold;
  span{
    background:#008cf0;
    height:30px;
    width:5px;
    margin:0 20px 0 0;
  }
}
.right-title{
  padding: 25px;
  line-height: 40px;
  .label{
    font-size:14px;
  }
  .date-btn{
    white-space:nowrap;
    text-decoration:underline ;
    margin:0 10px;
    color:#008cf0;
  }
  .front:before{
    content:"<<"
  }
  .queen:after{
    content:">>"
  }
}

.tab-header{
  //align-items:center;
  font-weight:bold;
  font-size:16px;
  background:#f3f9f9;
  // padding:10px 0;
}
.tab-header .min-w{
  height:50px;
  display: flex;
  align-items: center;
   padding-left: 20px;
  //flex-direction: column;
  //justify-content: center;
}
.tab-header, .tab-body-item{
  div{
    //text-align:center;
    font-size:14px;
    padding-left: 20px;
  }
}
/deep/ .el-checkbox-group{
  padding-left:0 !important;
}
/deep/.el-checkbox-group .el-checkbox{
  margin-right:15px;
}
.tab-body-item{
  color:$plain-color;
  // padding:10px 0;
  height:60px;
  line-height:60px;
  border-bottom:1px solid #f0f0f0;
  .price-ipt:nth-child(2){
    color:$plain-color;
    margin:0 0 0 2px;
  }
  .price-ipt{
    border:0;
    font-size:16px;
    //text-align:center;
    width:40%;
    border-radius:2px;
    padding:6px 0;
  }
  .price-ipt:focus,.price-ipt:hover{
    border-color:#409eff;
    outline:#409eff auto 0;
    box-shadow: 0 0 1px 1px #409eff;
  }
}
.tab-header .min-w,.tab-body-item .min-w{
  border-right:1px solid #f0f0f0;
}
.tab-header .min-w,.tab-body-item .min-w:nth-child(1){
  border-left:1px solid #f0f0f0;
}

.tab-body-item .min-w{
  height:60px;
}

.table-box{
  display: flex;
  align-items: center;
}

.c-red{
  color:$vip-color;
}
.min-w{
  min-width:calc(100%/8);
  overflow: hidden;
  white-space: nowrap;
}
.right-title{
  /deep/ .c-red .el-checkbox__label{
    color:$vip-color;
  }
  /deep/ .c-gray .el-checkbox__label{
    color:$plain-color;
  }
}
/deep/ .el-table th.gutter{
  /*
  * 解决element-ui 表格篡位的问题 👇
  */
  display: table-cell!important;
}
</style>
